.gradient_wrapper {
  position: relative;
  height: 100vh;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d0e4f7+0,73b1e7+24,0a77d5+50,539fe1+79,87bcea+100;Blue+Pipe+%231 */
  background: rgb(208, 228, 247); /* Old browsers */
  background: -moz-linear-gradient(45deg, rgba(208, 228, 247, 1) 0%, rgba(115, 177, 231, 1) 24%, rgba(10, 119, 213, 1) 50%, rgba(83, 159, 225, 1) 79%, rgba(135, 188, 234, 1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, rgba(208, 228, 247, 1) 0%, rgba(115, 177, 231, 1) 24%, rgba(10, 119, 213, 1) 50%, rgba(83, 159, 225, 1) 79%, rgba(135, 188, 234, 1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, rgba(208, 228, 247, 1) 0%, rgba(115, 177, 231, 1) 24%, rgba(10, 119, 213, 1) 50%, rgba(83, 159, 225, 1) 79%, rgba(135, 188, 234, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d0e4f7', endColorstr='#87bcea', GradientType=1); /* IE6-9 fallback on horizontal gradient */
  .gradient_box {
    background-color: white;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }

  #left_angle {
    position: absolute;
    left: 0;
  }

  #right_angle {
    position: absolute;
    right: 0;
    top: 10%;
  }

  #center_angle {
    position: absolute;
    bottom: 0;
    left: 20%
  }
  #gradient_main_logo{
    position: absolute;
    max-width: 500px;
    left: 50%;
    top: 10%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
}
