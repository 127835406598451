.server_detail_modal {
  max-width: 1200px !important;

  .server_detail_plan {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    .plan_item {
      align-items: center;
      margin-top: 1rem;
      border: 1px solid #cdcdcd;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0.5rem 0.5rem;
    }
  }

  section {
    padding: 1rem;
    margin: 2rem 0;

    .instance_status_show_wrapper {
      margin-left: 2rem;
    }
  }

  .server_control_btn_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    button {
      padding: 0.5rem 0;
      margin-top: 1rem;
      width: 33%;
      border: 1px solid #4D99FF;
      color: #4D99FF;
    }
  }

  .vultr_detail_txt {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    border: 1px solid #4D99FF;
    padding: 1rem;
    overflow-y: scroll;
    max-height: 500px;
    span{
      overflow-wrap: break-word;
      padding: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.4);
      margin-top: 1rem;
    }
  }

  .server_status_wrapper {
    .MuiChip-root {
      margin-left: 2rem;
    }
  }
}

.server_pending {
  color: orange;
  font-weight: bold;
}

.server_run {
  color: green;
  font-weight: bold;
}

.server_stop {
  color: rgba(0, 0, 0, 0.63);
  font-weight: bold;
}
.ticket_detail_btn{
  button{
    background-color: #4d99ff;
    padding: 0.8rem 1rem;
  }
  .comment_loader_btn{
      .comment_loader_icon{
        width: 25px!important;
        height: 25px!important;
        color: white;
      }
  }
}
.server_search_wrapper{
  padding: 1.4rem 0;
  display: flex;
  flex-direction: row;

}
select{
  appearance: auto!important;
}

.del_background{
  background-color: grey!important;
  td{
    color: white;
  }
}
