.log_table_control{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  button{
    margin-right: 2rem;
    padding: 1rem 2rem;
  }
}
.info_row{
  background-color: rgba(77, 153, 255, 0.12);
}
.error_row {
  background-color: rgba(255, 0, 0, 0.2);
}
.log_loading_wrapper{
  min-height: 72px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  small{
    font-weight: bold;
    margin-left: 1rem;
  }
}
.log_msg{
  max-width: 1000px;
}
