
.login_form_wrapper {
  padding: 3rem;

  #session_alreadyExpired{
    color: red;
  }
  a {
    color: #5f5f5f;
    &:hover{
      color: #5f5f5f;
    }
  }

  h2 {
    text-align: center;
    font-weight: bold;
    margin: 1rem 0;
  }

  label {
    color: #5f5f5f;
    font-size: 0.9rem;
  }

  .form-group {
    padding: 1rem 0;
  }

  .login_input_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid #d0d0d0;

    img {
      width: 20px;
      height: 20px;
      margin-right: 1rem;
      filter: invert(97%) sepia(0%) saturate(1247%) hue-rotate(261deg) brightness(83%) contrast(72%);
    }

    input {
      padding: 0.7rem 0;
      border: none;

      &:focus {
        border: none;
        box-shadow: none;
      }

      &::placeholder {
        color: #B8B8B8;
      }
    }
  }
  #signup_txt, #forget_txt {
    color: #73B1E7FF;
    font-weight: bold;
    &:hover{
      color: #4D99FF;
    }
  }
  .forget_pwd_wrapper {
    text-align: right;

    a {
      color: #5f5f5f;

    }
  }

  .social_login_wrapper {
    padding-top: 1rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      color: #B8B8B8;
    }

    .social_btn_wrapper {
      padding: 1rem 0;

      img {
        width: 40px;
        height: 40px;
        margin: 0 0.5rem;
      }
    }

  }

  .login_btn {
    color: white;
    width: 100%;
    border-radius: 20px;
    padding: 0.5rem 0;
    background-color: #73B1E7FF;
    border-color: #73B1E7FF;
    &:hover{
      background-color: #0a77d5;
    }
  }
}

