.ticket_wrapper {
  .ticket_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4rem 0;

    h2 {
      font-weight: bold;
    }
  }

  .faq_item_wrapper {
    border-radius: 5px;
    border: 1px solid rgba(179, 179, 179, 0.24);
    width: 100%;
    padding: 3rem 1rem;
    margin-top: 3rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

    .faq_title {
      h4 {
        margin-left: 2rem;
        font-weight: bold;
      }
    }

    .faq_list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;

      .faq_wrap {
        width: 45%;

        .faq_list_item {
          padding: 0.6rem 0;

          button {
            background-color: white;
            width: 100%;
            padding: 0.9rem 0.4rem;
            color: black;
            font-weight: bold;
            border: none;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 9px 0px;

            .faq_item {
              padding: 0 0.3rem;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            }
          }
        }
      }
    }

    .rotate_arrow {
      transform: rotate(0);
    }

    img {
      transform: rotate(-90deg);
      margin-right: 1rem;
      width: 15px;
    }

    .toggle_wrapper {
      background-color: white;
      padding: 1rem;
      color: #6f6f6f;
    }
  }

  .not_exist_table_wrapper {
    background-color: white;
  }
}

.ticket_modal_title {
  padding: 1rem 0;
}

.ticket_modal_input {
  margin: 2rem 0;
  color: rgba(0, 0, 0, 0.58);
}

.ticket_modal_txtArea {
  border-color: #CBCBCB;
  padding: 1rem;
}

.select_list_item {
  padding: 1rem !important;
}

.ticket_modal_file {
  border-radius: 3px;
  padding: 1rem;
  border: 1px solid #CBCBCB;

  label {
    font-size: 0.8rem;
  }
}

.ticket_modal_footer {
  justify-content: space-between !important;

  button {
    padding: 0.5rem 0;
    width: 49%;
  }
}

.ticket_detail_top_info {
  background-color: white;
  padding: 1rem;

  .info_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ticket_info_txt {
      margin-right: 3rem;
      font-size: 1.2rem;
      font-weight: bold;
    }

    .ticket_info_time, .ticket_info_status, .ticket_last_msg {
      margin-left: 2rem;
      font-size: 0.8rem;

      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .ticket_option_info {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;

    .ticket_info_data {
      margin-left: 2rem;

      span {
        font-weight: bold;
        margin-left: 2rem;

        &:nth-child(1) {
          color: #4d99ff;
        }

        &:nth-child(2) {
          color: #6b8660;
        }

        &:nth-child(3) {
          color: grey;
        }
      }
    }
  }
}

.ticket_detail_wrapper {
  padding: 0 !important;
  margin-bottom: 2rem;

  .ticket_detail_top {
    margin: 0 auto;
    max-width: 1188px;
    padding: 3rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .ticket_detail_info {
      span {
        color: #a2a2a2;
        margin-right: 2rem;
      }
    }
  }

  .company_ticket_wrapper, .user_ticket_wrapper {
    padding: 2rem 1.6rem;
    margin: 1rem 0;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 10px;
  }

  .manager_ticket {
    background-color: #F4F8FA;

    .ticket_comment {
      background-color: white;
    }
  }

  .customer_ticket {
    background-color: white;

    .ticket_comment {
      background-color: #F4F8FA;
    }
  }

  .ticket_detail_content {
    background-color: white;
    display: flex;
    justify-content: center;

    .ticket_detail_box {
      margin-top: 1rem;
      width: 90%;
    }

    .ticket_info {
      width: 100%;
      padding: 1rem 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .user_info {
        display: flex;
        flex-direction: column;

        #user_rank {
          margin-top: 0.5rem;
          border-radius: 5px !important;
        }
      }

      #ticket_date {
        color: #a2a2a2;
        font-size: 0.8rem;
      }

      #ticket_nickname {
        font-weight: bold;
      }
    }

    .ticket_content {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      flex-direction: row;

      .ticket_comment {
        width: 80%;
      }

      .user_ticket_attachment {
        width: 20%;
        text-align: left;

        .ticket_attachment_item {
          font-size: 0.8rem;
          padding-left: 1rem;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        img {
          margin-top: 1rem;
          max-height: 50px;
          max-width: 80px;
        }
      }

      p {
        color: rgba(0, 0, 0, 0.62);
        font-weight: bolder;
        font-size: 0.9rem;
        padding: 2rem;
      }
    }

    .ticket_attach {
      display: flex;
      flex-direction: column;
      padding: 1rem 0;
    }
  }

  .ticket_detail_writer_wrapper {
    width: 100%;
    padding: 1rem 0;

    .ticket_detail_writer {
      position: relative;

      .ticket_detail_input {
        background-color: #F4F8FA;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border: none;
        border-radius: 5px;
        padding: 1rem 1rem 5rem 1rem;
      }

      button {
        width: 160px;
        position: absolute;
        right: 15px;
        bottom: 20px;
      }

      .ticket_detail_file {
        width: 50%;
        position: absolute;
        left: 2%;
        bottom: 5%;
      }
    }
  }
}

.ticket_filter_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0;

  #ticket_status_sel {
    -webkit-appearance: inherit;
    appearance: auto;
    min-width: 150px;
    padding: 0.7rem 0.5rem;

    span {
      padding-left: 0.4rem;
    }
  }

  .ticket_search_wrapper {
    min-width: 240px;

    input {
      padding: 0.7rem 25px;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-position: 5px 15px;
    }

  }
}

.ticket_table {
  .ticket_row_wrapper {
    &:hover {
      .table_item, .table_status {
        background-color: rgba(0, 0, 0, 0.04) !important;
      }

    }
  }

  .table_item {
    padding: 1.5rem 0;
  }

  .table_status {
    max-height: 68px;
    padding: 1rem 0;

    .status_chip {
      width: 100px;
      border-radius: 8px !important;
      padding: 1.2rem;

      span {
        padding: 0;
      }
    }
  }

  td:first-child {
    .table_item {
      border-top-left-radius: 7px;
      border-bottom-left-radius: 7px;
      padding-left: 1rem !important;
    }
  }

  td:last-child {
    .table_status {
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
    }
  }

  .empty_ticket_table {
    width: 100%;
    min-height: 400px;
    position: relative;
    display: table-caption;

    .empty_ticket_content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;

      h3 {
        color: #9e9e9e;
      }

      button {
        margin-top: 1rem;
        padding: 0.5rem 2rem;
      }
    }
  }
}

.ticket_add_modal_wrapper {
  max-width: 700px !important;

  .list_skeleton_wrapper {
    margin-top: 2rem !important;
  }
}
