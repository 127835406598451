.point_info_modal_wrapper {
  max-width: 1200px !important;

  .modal-content {
    min-height: 900px;
    overflow-y: scroll;
    max-height: 1000px !important;
  }

}

.box_title {
  font-size: 1.2rem;
  font-weight: bold;
}

.billing_title {
  padding: 3rem 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h2 {
    font-weight: bold;
  }

  .holding_point_wrapper {
    padding: 0 2rem;
    color: #0a77d5;

    h3, h2 {
      font-weight: bold;
    }

    h2 {
      margin-top: 1rem !important;
    }
  }
}

.billing_info_container {
  padding: 2rem 0;
  justify-content: space-around;

  .billing_left_wrapper {
    .billing_info_wrapper {
      float: left;
    }
  }

  .billing_right_wrapper {
    .billing_info_wrapper {
      float: right;
    }
  }

  .billing_info_wrapper {
    width: 95%;
    justify-content: space-between;

    .bill_box_wrapper {
      border-radius: 10px;
      background-color: white;
      padding: 1rem;
      margin-bottom: 2rem;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .bill_box_title {
        margin-bottom: 1.5rem;
      }

      .bill_box_row {
        display: flex;
        flex-direction: column;

        .bill_box_item {
          padding: 0.5rem 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          span {
            &:first-child {
              color: #8297AA;
            }
          }
        }
      }
    }
  }
}


table, thead, tbody, tr {
  border-bottom: none;
}

th, td {
  border-bottom: none;
}

.billing_charge_wrapper {
  .charge_title {
    padding: 2rem 1rem;
  }
}

.charging_point_wrapper {
  background-color: white;
  padding: 2rem 1rem;

  .charging_point_price {
    .point_price_label {
      min-width: 100px;
    }

    .point_price_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .point_price_item {
        cursor: pointer;
        border: 1.4px solid rgba(0, 0, 0, 0.18);
        padding: 0.5rem;
        border-radius: 5px;

        &.select_price {
          border-color: #0a77d5;
          color: #0a77d5;
        }

        .top_point {
          padding: 1rem 1rem;
          border-bottom: 1px solid #4D99FF;
        }

        .bottom_price {
          padding: 1rem;
        }
      }
    }
  }

  .charging_point_type {
    margin-top: 2rem;

    .charging_point_type_item {
      margin-top: 0.6rem;
      text-align: center;
      padding: 1rem;
      border: 1px solid rgba(0, 0, 0, 0.18);
      cursor: pointer;
      border-radius: 5px;

      &.select_type {
        border-color: #0a77d5;
        color: #0a77d5;
      }

      .type_img {
        img {
          width: 50px;
        }

        .gray_img {
          -webkit-filter: opacity(.5) drop-shadow(0 0 0 gray);
          filter: opacity(.5) drop-shadow(0 0 0 gray);
        }
      }
    }
  }

  .payment_policy_wrapper {
    margin-top: 2rem;

    .policy_txt_wrapper {
      min-height: 200px;
      background-color: rgba(245, 245, 245, 0.58);
      border-radius: 5px;
      overflow: scroll;

      .policy_txt {
        padding: 1rem;
        color: rgba(0, 0, 0, 0.65);
        font-size: 0.7rem;
      }
    }

    .agreement_checkbox {
      padding: 1rem 2rem;
    }
  }

  .charging_point_btn {
    text-align: right;

    button {
      padding: 0.8rem 3.5rem;
    }
  }

  label {
    font-weight: bold;
    color: #8297AA;
    padding: 0.5rem 0;
  }

  input {
    padding: 0.7rem 10px;
  }

  button {
    margin: 2rem 0;
    font-size: 1.1rem;
    font-weight: bold;
    padding: 0.8rem 0;
  }

  .plus_point_btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      width: 22%;
    }
  }

  .charging_point_input {
    position: relative;

  }


}

.billing_list_wrapper {
  background-color: white;
  margin-top: 2rem;
  border-radius: 10px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  padding: 1.5rem;

  .billing_list_title {
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
  }
}

.billing_info_wrapper {
  padding: 1rem 0;
}

.point_usage_wrapper {
  background-color: white;
}


.payment_request_wrapper {

  min-height: calc(100vh - 200px);
  position: relative;

  .success_pay_wrapper {
    background-color: white;
    padding: 3rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .pay_btn_wrapper {
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .pay_btn_item {
        padding: 0.5rem;
        width: 45%;
      }
    }

    .success_bill_title {
      text-align: center;

      h4 {
        color: #0a77d5;
        padding-bottom: 1rem;
      }

      span {
        color: #8297AA;
      }
    }

    .success_bill {
      border: 3px solid #8297AA;
      margin-top: 1rem;
      display: flex;
      flex-direction: row;
      padding: 2rem;
      justify-content: space-between;

      .bill_item {
        display: flex;
        flex-direction: column;

        span {
          padding: 0.5rem 0;
          border-bottom: 1px solid rgba(0, 0, 0, 0.25);
        }

        &:first-child {
          color: #8297AA;
        }

        &:last-child {
          text-align: left;

          span {
            padding-left: 1rem;
          }
        }
      }
    }

  }


  .payment_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      padding: 2rem 0;
    }
  }
}

.point_manage_wrapper {
  section {
    padding: 2rem;

    button {
      margin-top: 1rem;
      padding: 0.4rem 0;
      color: white;
      background-color: #4D99FF;
      width: 100%;
      &:hover {
        background-color: #0a77d5 !important;
      }
    }
  }
}

.minus_point_check_bot_wrapper{
  margin-top: 5rem;
  padding: 2rem;
  .bot_controller{
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 3rem;
    button{
      margin-top: 2rem;
    }
  }
  .bot_log_box{
    height: 500px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    overflow: scroll;
    .bot_log_item{
      padding: 2rem;
    }
  }
}
